import URLS from "@/constants/urls";

const parseImageURL2 = function (
  imageID: string | null | undefined,
  size?: number,
) {
  return imageID
    ? `${URLS.CMSKIT}/media/${imageID}?width=${size ?? 700}&quality=80`
    : `https://picsum.photos/${size ?? 700}`;
};

export default parseImageURL2;
